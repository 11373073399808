import { MailOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select, Space, Spin, Typography, message } from 'antd';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../../context/UserContext';
import { api } from '../../service/api';

const { Title } = Typography;

const phoneRegex = /^[ ]*(\d{3}|\(\d{3}\))[- ]?\d{3}[- ]?\d{4}[ ]*$/;

function ContactInformation({ phoneCarriers }) {
    const { setEmail, setFullForm, setPhone: setPhoneContext, setPhoneCarrier, setId } = useUser();
    const navigate = useNavigate();
    const phoneCarrierOtherInputRef = useRef(null);
    const [phoneCarriersState, setPhoneCarrierStates] = useState(
        phoneCarriers.map((value) => ({ label: value, value }))
    );

    const [loading, setLoading] = useState(false);

    const [phoneCarrierInSpecify, setPhoneCarrierInSpecify] = useState('');
    const addPhoneCarrierItem = (e) => {
        e.preventDefault();
        setPhoneCarrierStates([...phoneCarriersState, { label: phoneCarrierInSpecify, value: phoneCarrierInSpecify }]);
        setPhoneCarrierInSpecify('');
        setTimeout(() => {
            phoneCarrierOtherInputRef.current?.focus();
        }, 0);
    };
    const onChangePhoneCarrierInSpecify = (e) => {
        setPhoneCarrierInSpecify(e.target.value);
    };

    const error_message = 'This field is required';

    const handleBenefitRegister = (formValues) => {
        let newPhone = formValues.phone;
        if (phoneRegex.test(formValues.phone)) {
            newPhone = formValues.phone.replace(/[^0-9]/g, '');
        }
        setLoading(true);
        api.registerBenefit({ ...formValues, phone: newPhone })
            .then(async (response) => {
                if (response.status !== 200) {
                    return Promise.reject(response.body ? await response.json() : response.statusText);
                }
                const data = await response.json();
                console.log(data);
                setLoading(false);
                setFullForm(data.formType.toLowerCase() === 'full');
                setEmail(formValues.email);
                setPhoneContext(formValues.phone);
                setPhoneCarrier(formValues.phoneCarrier);
                setId(data.id);

                navigate('/snap');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                let errorMessage = 'There was an error registering. ';
                errorMessage += error?.errors?.email
                    ? ` ${error.errors.email}`
                    : 'Please check the email account provided is correct and try again.';
                message.error(errorMessage);
            });
    };

    return (
        <Spin spinning={loading}>
            <Col>
                <Row className='row-padding' justify='space-around' align='middle'>
                    <Col span={20}>
                        <Title>SNAP Benefit Indicator</Title>
                        <p>
                            Note: We will use the email address and phone number you provide to send a very short
                            follow-up email inquiring whether or not you end up applying for SNAP.
                        </p>
                    </Col>
                </Row>
                <Row className='row-padding' justify='space-around' align='middle'>
                    <Col span={6}>
                        <Form
                            onFinish={handleBenefitRegister}
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <Form.Item
                                name='email'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email',
                                        type: 'email',
                                    },
                                ]}
                                label={<strong>Email</strong>}
                            >
                                <Input
                                    prefix={<MailOutlined className='site-form-item-icon' />}
                                    placeholder='example@email.com'
                                />
                            </Form.Item>
                            <Divider dashed />
                            <Form.Item
                                name='phone'
                                rules={[{ required: true }]}
                                label={<strong>Cell Phone Number</strong>}
                            >
                                <Input placeholder='111-111-1111' />
                            </Form.Item>
                            <Divider dashed />
                            <Form.Item
                                name='phoneCarrier'
                                rules={[{ required: true, message: error_message }]}
                                label={<strong>Phone Carrier</strong>}
                            >
                                <Select
                                    placeholder='Select one'
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                Other
                                                <Input
                                                    value={phoneCarrierInSpecify}
                                                    placeholder='Please specify'
                                                    onChange={onChangePhoneCarrierInSpecify}
                                                    ref={phoneCarrierOtherInputRef}
                                                />
                                                <Button
                                                    type='text'
                                                    icon={<PlusCircleFilled />}
                                                    onClick={addPhoneCarrierItem}
                                                ></Button>
                                            </Space>
                                        </>
                                    )}
                                    options={phoneCarriersState}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Row>
                                    <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                                        Continue
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Col>
        </Spin>
    );
}

export default ContactInformation;
