import ReactGA from 'react-ga4';

import { config } from './config/config';

ReactGA.initialize(config.googleAnalyticsId, {
    debug: config.nodeEnv === 'development',
});

const GA = () => {
    return null;
};

export { GA };
