import { config } from '../config/config';

const baseURL = config.baseUrl;

export const api = {
    registerBenefit,
    submitSNAPForm,
    submitFeedback,
};

// -- Users

function registerBenefit(data) {
    return fetch(baseURL + '/api/benefit/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

function submitSNAPForm(data, fullForm) {
    return fetch(baseURL + `/api/submit/${fullForm ? 'full' : 'demographics'}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

function submitFeedback(data) {
    return fetch(baseURL + '/api/feedback', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}
