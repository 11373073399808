import { Button, Col, Form, List, Modal, Rate, Row, Space, Spin, Typography, message } from 'antd';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import HarrisCounty from '../assets/img/harris_county.svg';
import TomGreenCounty from '../assets/img/tomgreen_county.png';
import { useUser } from '../context/UserContext';
import { api } from '../service/api';

const { Text, Link } = Typography;

const countySupport = [
    {
        title: 'Harris County Support',
        imgSrc: HarrisCounty,
        resources: [
            {
                name: 'Houston Food Bank',
                link: 'https://www.houstonfoodbank.org/find-help/snap/',
            },
            {
                name: 'Memorial Hermann Community Benefit CorporationMemorial',
                address: ['Hermann Resource Center', '7777 Southwest Freeway, Suite 110,', 'Houston, TX 77074'],
                link: 'https://memorialhermann.org/giving-back/community-benefit/programs/community-resource-centers',
                phone: '(713) 456-6814',
            },
            {
                name: 'Light and Salt Association',
                address: ['3535 Briarpark Drive Unit 135,', 'Houston, TX 77042'],
                link: 'https://www.light-salt.org',
                phone: '(713) 988-4724',
            },
            {
                name: "Children's Defense Fund - Texas",
                address: ['5410 Bellaire Blvd. Ste 203, Bellaire, TX 77401'],
                link: 'https://cdftexas.org/programs/beat-the-odds/',
                phone: '(713) 664-4080',
            },
            {
                name: 'Community Family Centers-Centros Familiares De La ComunidadCommunity',
                address: ['7524 Avenue E,', 'Houston, TX 77012'],
                link: 'https://www.communityfamilycenters.org/',
                phone: '(713) 923-2316',
            },
            {
                name: "God's Lovely Butterflies Maternity Home",
                address: ['Faith & Favor Multi-Services2626 S Loop W, Suite 174, Houston, TX 77054'],
                link: 'https://www.godslovelybutterflies.org/',
                phone: '(832) 264-7491',
            },
            {
                name: 'Houston Area Community Services',
                address: [
                    'Avenue 360 Health and Wellness-Heights',
                    '2150 West 18th Street, Suite 300, Houston, TX 77008',
                ],
                link: 'https://avenue360.org/',
                phone: '(713) 426-0027',
            },
            {
                name: 'Covenant House Texas',
                address: ['1111 Lovett Blvd., Houston, TX 77006'],
                link: 'https://www.covenanthousetx.org/',
                phone: '(713) 523-2231',
            },
            {
                name: 'An Nisa Hope Center',
                address: ['7100 Regency Square Blvd. Suite 290, Houston, TX 77036'],
                link: 'https://annisa.org/',
                phone: '(832) 324-9111',
            },
            {
                name: 'Memorial Assistance Ministries',
                address: ['1625 Blalock Road, Houston, TX 77080'],
                link: 'https://www.maministries.org/',
                phone: '(713) 486-4516',
            },
            {
                name: 'Divine Grace Medical Missionaries',
                address: ['11000 Fondren Rd, Suite C3, Houston, TX 77096'],
                link: 'http://www.divinegracemedical.org/',
                phone: '(713) 773-1232',
            },
            {
                name: 'Boat People SOS',
                address: ['11360 Bellaire Blvd, Suite 910, Houston, TX 77072'],
                link: 'https://www.bpsos.org/bpsos-houston',
                phone: '(281) 530-6888',
            },
            {
                name: 'Epiphany Community Health Outreach Services (ECHOS)',
                address: ['9600 S Gessner Rd, Building E, Houston, TX 77071'],
                link: 'https://echoshouston.org/',
                phone: '(713) 270-0369',
            },
            {
                name: 'Spring Branch Community Health Center',
                address: ['1615 Hillendahl Blvd., Suite 100, Houston, TX 77055'],
                link: 'https://sbchc.net/',
                phone: '(713) 462-6565',
            },
            {
                name: 'Family Service Center At Houston and Harris County',
                address: ['4950 Memorial Drive, Houston, TX 77007'],
                link: 'https://familyhouston.org/',
                phone: '(713) 861-4849',
            },
            {
                name: 'Houston Area Urban League',
                address: ['5260 Griggs Rd, Houston, TX 77021'],
                link: 'https://www.haul.org/',
                phone: '(281) 220-6012',
            },
            {
                name: 'Talent Yield Coalition, Inc',
                address: ['Marcelous-Williams Resource Center', '811 N Main, Highlands, TX 77562'],
                link: 'https://www.talentyieldcoalition.org/',
                phone: '(855) 449-1472',
            },
            {
                name: 'Civic Heart',
                address: ['3353 Elgin St, Houston, TX 77004'],
                link: 'https://civicheart.org/',
                phone: '(713) 374-1282',
            },
            {
                name: 'Pasadena Health Center, Inc.',
                address: ['908 Southmore Ave, Suite 100, Pasadena, TX 77502'],
                link: 'https://www.pasadenahealthcenter.com/',
                phone: '(713) 554-1091',
            },
            {
                name: 'Launch Point CDC, Inc',
                address: ['5807 Little York Rd., Houston, TX 77016'],
                link: 'https://thelaunchcenter.org/',
                phone: '(713) 636-2959',
            },
            {
                name: 'Chinese Community Center',
                address: ['9800 Town Park Dr, Houston, TX 77036'],
                link: 'https://ccchouston.org/',
                phone: '(713) 271-6100',
            },
            {
                name: 'Wesley Community Center, Inc of Houston',
                address: ['1410 Lee Street, Houston, TX 77009'],
                link: 'https://wesleyhousehouston.org/',
                phone: '(713) 223-8131',
            },
            {
                name: 'Southeast Area Ministries',
                address: ['2102 Houston Blvd., South Houston, TX 77587'],
                link: 'https://southeastareaministries.com/',
                phone: '(713) 944-0093',
            },
            {
                name: 'Spring Branch Community Health Center',
                address: ['West Houston Community Health Center', '19333 Clay Road, Katy, TX 77449'],
                link: 'https://sbchc.net/',
                phone: '(713) 462-6555',
            },
            {
                name: 'Legacy Community Health Services, INC',
                address: ['Legacy Montrose Clinic (LMC)', '1415 California Street, Houston, TX 77006'],
                link: 'https://www.legacycommunityhealth.org/',
                phone: '(713) 830-3000',
            },
            {
                name: 'Humble Area Assistance Ministries, Inc. (HAAM)',
                address: ['1302 1st Street E., Humble, TX 77338'],
                link: 'https://haamministries.org/',
                phone: '(281) 446-3663',
            },
            {
                name: 'North Pasadena Community Outreach Inc.',
                address: ['705 1/2 Williams St, Pasadena, TX 77506'],
                link: 'https://www.npcocenter.org/',
                phone: '(713) 473-6300',
            },
            {
                name: 'Target Hunger',
                address: ['St. Peter Claver Catholic Church', '6005 N Wayside Dr., Houston, TX 77028'],
                link: 'https://www.targethunger.org/',
                phone: '(832) 629-4866',
            },
            {
                name: 'Spring Branch Community Health Center',
                address: ['WholeLife Clinic', '1905 Jacquelyn Dr., Suite 101, Houston, TX 77055'],
                link: 'https://sbchc.net/',
                phone: '(713) 231-5767',
            },
            {
                name: 'City of Houston Health Department',
                address: ['Community Cares Magnolia MSC', '7037 Capitol St., Houston, TX 77011'],
                link: 'https://www.houstontx.gov/health/',
                phone: '(832) 395-0478',
            },
            {
                name: 'Living Water International Apostolic Ministries',
                address: ['7802 Jensen Drive, Houton, TX 77093'],
                link: 'https://livingwateriam.net/',
                phone: '(281) 936-8175',
            },
            {
                name: "The Women's Home",
                address: ['Mabee WholeLife Service Center', '1905 Jacqueline Drive, Houston, TX 77055'],
                link: 'https://www.thewomenshome.org/',
                phone: '(713) 328-1963',
            },
            {
                name: 'City of Houston Health Department',
                address: ['Nurse Family SS', '9314 Cullen, Houston, TX 77051'],
                link: 'https://www.houstontx.gov/health',
                phone: '(832) 395-0006',
            },
            {
                name: 'Young Mens Christian Association of Greater Houston Area',
                address: ['YMCA International Services', '7850 Howell Sugar land Rd., Houston, TX 77083'],
                link: 'https://ymcahouston.org/locations/ymca-international-services',
                phone: '(713) 339-9015',
            },
            {
                name: 'Harris County Public Health',
                link: 'https://publichealth.harriscountytx.gov/Services-Programs/All-Services/HCPH-Connect',
                phone: '(832) 927-7617',
            },
        ],
    },
    {
        title: 'Tom Green County Support',
        imgSrc: TomGreenCounty,
        resources: [
            {
                name: 'Concho Valley Regional Food Bank',
                address: ['1313 S. Hill St.'],
                link: 'https://conchovalleyfoodbank.org/',
            },

            {
                name: 'La Esperanza Clinics',
                address: ['1610 S. Chadbourne and 35 E. 31st'],
                link: 'https://www.esperanzahealth.org/',
            },

            {
                name: 'Area Agency on Aging of the Concho Valley',
                address: ['5430 Link Rd.'],
                link: 'https://www.cvcog.org/cvcog/AAA.html',
            },

            {
                name: 'Disability Connections Resource Center',
                address: ['2809 Southwest Blvd.'],
                link: 'https://www.dcciltx.org/',
            },

            {
                name: 'Concho Valley Community Action Agency',
                address: ['133 W Concho Ave. Suite 301.'],
                link: 'https://www.cvcaa.org/',
            },
        ],
    },
];

function Final() {
    const { benefitAmount, email, id } = useUser();
    const [countySupportModalOpenIndex, setCountySupportModalOpenIndex] = useState(-1);

    const [loading, setLoading] = useState(false);
    const [rate, setRate] = useState(0);

    const { Title } = Typography;

    const handleRateSubmit = (values) => {
        setLoading(true);
        console.log(values);
        var data = { rate: +rate, email, id };
        api.submitFeedback(data)
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    message.success('Your feedback has been submitted. Thank you for your time!');
                    return Promise.resolve();
                }

                return Promise.reject(response.json());
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error(
                    'There was an error submitting your feedback. Please, check your internet connection and try again.'
                );
            });
    };

    const countySupportModalHandleOk = () => {
        setCountySupportModalOpenIndex(-1);
    };
    const countySupportModalHandleCancel = () => {
        setCountySupportModalOpenIndex(-1);
    };

    return !email ? (
        <Navigate to='/' />
    ) : (
        <Spin spinning={loading}>
            <Row className='row-padding' justify='space-around' align='middle'>
                <Col span={20}>
                    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                        <Title>SNAP Benefit Indicator</Title>
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginTop: '2rem', marginBottom: '2rem' }} justify={'center'}>
                <Text>
                    {`If you apply for SNAP and are approved, your benefit amount may be:`}
                    <br />
                    <Row style={{ marginTop: '1rem' }} justify={'center'}>
                        <Text strong style={{ fontSize: '2.5rem' }}>{`$${benefitAmount.toFixed(2)} per month`}</Text>
                    </Row>
                </Text>
            </Row>
            <Row justify={'center'}>
                <Text>
                    If you are interested in applying for SNAP, you can fill out a SNAP application form at:{' '}
                    <Row justify={'center'}>
                        <Link href='https://www.yourtexasbenefits.com/Learn/Home' target='_blank'>
                            https://www.yourtexasbenefits.com/Learn/Home
                        </Link>
                    </Row>
                </Text>
            </Row>
            <Row justify={'center'} style={{ marginTop: '1rem' }}>
                <Text>
                    If you live in Harris County or Tom Green County and would like additional resources on how to apply
                    for SNAP, or support with applying for SNAP, follow the links below for more information.
                </Text>
            </Row>
            <Row justify={'center'} style={{ marginTop: '2rem' }}>
                <List>
                    {countySupport.map((county, i) => (
                        <List.Item style={{ justifyContent: 'left' }}>
                            <img src={county.imgSrc} style={{ height: '50px', padding: '5px' }} />
                            <Link href='#' onClick={() => setCountySupportModalOpenIndex(i)}>
                                {county.title}
                            </Link>
                            <Modal
                                title={county.title}
                                open={countySupportModalOpenIndex === i}
                                onOk={countySupportModalHandleOk}
                                onCancel={countySupportModalHandleCancel}
                                width={'50%'}
                            >
                                {county.resources.map((resource) => (
                                    <div>
                                        <Text strong>{resource.name}</Text>
                                        <br />
                                        {resource.address && resource.address.length > 0 && (
                                            <>
                                                {resource.address.map((line) => (
                                                    <>
                                                        <Text>{line}</Text>
                                                        <br />
                                                    </>
                                                ))}
                                            </>
                                        )}
                                        {resource.link && (
                                            <>
                                                <Link href={resource.link} target='_blank'>
                                                    {resource.link}
                                                </Link>
                                                <br />
                                            </>
                                        )}
                                        {resource.phone && (
                                            <>
                                                <Text>{resource.phone}</Text>
                                                <br />
                                            </>
                                        )}
                                        <br />
                                    </div>
                                ))}
                            </Modal>
                        </List.Item>
                    ))}
                </List>
            </Row>

            <Row justify={'center'}>
                <Form onFinish={handleRateSubmit} initialValues={{ rate: 0 }} layout='vertical'>
                    <Form.Item name='rate' label='How satisfied were you with the SNAP Benefit Indicator?'>
                        <Row justify={'center'}>
                            <Rate count={10} onChange={setRate} />
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row justify={'center'}>
                            <Button type='primary' htmlType='submit'>
                                Submit
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Row>
        </Spin>
    );
}

export default Final;
