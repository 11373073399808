import { createBrowserHistory } from 'history';
import React, { useContext, useState } from 'react';

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
    const [fullForm, setFullForm] = useState();
    const [email, setEmail] = useState();
    const [benefitAmount, setBenefitAmount] = useState();
    const [phone, setPhone] = useState();
    const [phoneCarrier, setPhoneCarrier] = useState();
    const [id, setId] = useState();
    const history = createBrowserHistory();

    const isFormCompleted = () => {
        console.log(benefitAmount);
        return benefitAmount !== null && benefitAmount !== undefined;
    };

    return (
        <UserContext.Provider
            value={{
                email,
                setEmail,
                fullForm,
                setFullForm,
                phone,
                setPhone,
                phoneCarrier,
                setPhoneCarrier,
                id,
                setId,
                benefitAmount,
                setBenefitAmount,
                isFormCompleted,
                history,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;

export function useUser() {
    return useContext(UserContext);
}
