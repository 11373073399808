import { Layout } from 'antd';
import React from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { Navigate, Route, unstable_HistoryRouter as Router, Routes } from 'react-router-dom';

import './App.css';
import FooterContents from './components/FooterContents';
import HeaderContents from './components/HeaderContents';
import { useUser } from './context/UserContext';
import { GA } from './google-analitics';
import Final from './pages/Final';
import ContactInformation from './pages/Landing/ContactInformation';
import Landing from './pages/Landing/Landing';
import SNAPForm from './pages/SNAPForm';

const { Content, Footer, Header } = Layout;

function App() {
    const householdLimit = 10;
    const ethnicities = ['Hispanic/Latino/a', 'Non-Hispanic', 'Prefer not to answer'];
    const phoneCarriers = ['AT&T', 'Boost Mobile', 'Cricket Wireless', 'Metro PCS', 'Sprint', 'T-Mobile', 'Verizon'];
    const races = [
        'American Indian or Alaska Native',
        'Asian',
        'Black or African American',
        'Native Hawaiian or Pacific Islander',
        'White or Caucasian',
        'Multi-racial',
        'Prefer not to answer',
    ];
    const genders = ['Male', 'Female', 'Non-binary', 'Prefer not to answer'];
    const { history } = useUser();

    const unblock = history.block((tx) => {
        if (tx.action === 'PUSH') {
            unblock();
            tx.retry();
        }
    });

    useBeforeunload(() => 'You’ll lose your data!');

    return (
        <Router history={history}>
            <GA />
            <Layout>
                <Header style={{ backgroundColor: '#154734' }}>
                    <HeaderContents />
                </Header>
                <Content>
                    <Routes>
                        <Route path='/' element={<Landing />} />
                        <Route path='/contact-info' element={<ContactInformation {...{ phoneCarriers }} />} />
                        <Route
                            path='/snap'
                            element={
                                <SNAPForm
                                    {...{
                                        householdLimit,
                                        ethnicities,
                                        races,
                                        genders,
                                    }}
                                />
                            }
                        />
                        <Route path='/answer' element={<Final />} />
                        <Route path='*' element={<Navigate to='/' replace />} />
                    </Routes>
                </Content>
                <Footer>
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <FooterContents disclaimer='This website is not affiliated with any government organization.' />
                            }
                        />
                        <Route
                            path='/answer'
                            element={
                                <FooterContents disclaimer='The amount shown above may not reflect the amount you would actually receive after applying for SNAP. This website is not affiliated with any government organization.' />
                            }
                        />
                        <Route path='*' element={<FooterContents />} />
                    </Routes>
                </Footer>
            </Layout>
        </Router>
    );
}

export default App;
