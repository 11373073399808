import React from 'react';

function CalculatorDescription() {
    return (
        <>
            <p>
                The SNAP Benefit Indicator is a tool that provides you with an estimate of what your SNAP benefit amount
                may be if you were to apply through the Texas Health and Human Services Commission.
                <br />
                <br />
                Unsure if SNAP is the right solution for you? Try the SNAP Benefit Indicator to determine how you can
                help bring stability to your household’s food budget!
            </p>
            <p>
                <h2>Purpose of the SNAP Benefit Indicator</h2>
                The Baylor Collaborative on Hunger and Poverty utilizes research, policy, and practice to cultivate
                evidence-based solutions to the challenges of food and economic injustice. As part of a research study,
                The Collaborative has designed the SNAP Benefit Indicator to understand the best approach to providing
                information about possible SNAP benefits and future enrollment in the program. Your answers to the
                following questions will be collected and used only for the purposes of this study, and will remain
                confidential.
            </p>
        </>
    );
}

export default CalculatorDescription;
