import { Col, Row } from 'antd';
import React from 'react';

import LogoGold from '../assets/img/logo-gold.png';

function HeaderContents() {
    return (
        <>
            <Row style={{ height: 'inherit' }} align='middle'>
                <Col span={2} style={{ height: 'inherit' }}>
                    <img src={LogoGold} style={{ height: 'inherit', padding: '5px' }} />
                </Col>
            </Row>
        </>
    );
}

export default HeaderContents;
