import { PlusCircleFilled } from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Space,
    Spin,
    Typography,
    message,
} from 'antd';
import React, { useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useUser } from '../context/UserContext';
import { api } from '../service/api';

function SNAPForm({ householdLimit, ethnicities, races, genders }) {
    const { email, phone, phoneCarrier, id, fullForm, setBenefitAmount } = useUser();

    const navigate = useNavigate();
    const raceInSpecifyInputRef = useRef(null);
    const genderInSpecifyInputRef = useRef(null);
    const [racesState, setRacesState] = useState(races.map((value) => ({ label: value, value })));
    const [gendersState, setGendersState] = useState(genders.map((value) => ({ label: value, value })));
    const [pageIndex, setPageIndex] = useState(0);
    const lastPageIndex = 3;

    const onYearChange = (year) => {
        console.log(year);
    };

    const [raceInSpecify, setRaceInSpecify] = useState('');
    const addRaceItem = (e) => {
        e.preventDefault();
        setRacesState([...racesState, { label: raceInSpecify, value: raceInSpecify }]);
        setRaceInSpecify('');
        setTimeout(() => {
            raceInSpecifyInputRef.current?.focus();
        }, 0);
    };
    const onChangeRaceSpecify = (e) => {
        setRaceInSpecify(e.target.value);
    };

    const [genderInSpecify, setGenderInSpecify] = useState('');
    const addGenderItem = (e) => {
        e.preventDefault();
        setGendersState([...gendersState, { label: genderInSpecify, value: genderInSpecify }]);
        setGenderInSpecify('');
        setTimeout(() => {
            genderInSpecifyInputRef.current?.focus();
        }, 0);
    };

    const onChangeGenderSpecify = (e) => {
        setGenderInSpecify(e.target.value);
    };

    const [disPayValue, setDisPayValue] = useState('no');
    const [anyDisabled, setAnyoneDisabled] = useState('no');
    const [childSupportOutside, setChildSupportOutside] = useState('no');
    const [loading, setLoading] = useState(false);

    const { Title, Text } = Typography;

    const handleSNAPFormSubmit = (values) => {
        setLoading(true);
        const snap_data = { values: { ...values, email, phone, phoneCarrier, id } };
        api.submitSNAPForm(snap_data, fullForm)
            .then(async (response) => {
                if (response.status === 200) {
                    const data = await response.json();
                    setBenefitAmount(data.benefitAmount);
                    setLoading(false);
                    navigate('/answer');
                    return Promise.resolve();
                }

                return Promise.reject(response.json());
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error(
                    'There was an error submitting your form. Please, check your internet connection and try again.'
                );
            });
    };

    const handleContinue = () => {
        if (pageIndex === 0 && !fullForm) {
            setPageIndex(3);
        } else {
            setPageIndex(pageIndex + 1);
        }
    };

    const error_message = 'This field is required';

    if (!email) {
        navigate('/');
    }
    return !email ? (
        <Navigate to='/' />
    ) : (
        <Spin spinning={loading}>
            <Row className='row-padding' justify='space-around' align='middle'>
                <Col span={20}>
                    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                        <Title>SNAP Benefit Indicator</Title>
                        <div hidden={pageIndex === lastPageIndex}>
                            <Text type='danger'>
                                The SNAP Benefit Indicator is not an official SNAP application. At the conclusion of the
                                SNAP Benefit Indicator, we will share a link to the Texas SNAP application where you can
                                apply for benefits, if you are so inclined.
                            </Text>
                            <Title level={5}>
                                All entries are required. Please enter as precisely as you can. If you do not know the
                                exact value, just enter an approximate amount.
                            </Title>
                        </div>
                        <div hidden={pageIndex !== lastPageIndex}>
                            <Title level={5}>
                                These next questions are about you. Your responses will help us understand who
                                participates in this study.
                            </Title>
                        </div>

                        <Form
                            labelCol={{
                                span: 16,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            layout='vertical'
                            onFinish={handleSNAPFormSubmit}
                            initialValues={{
                                remember: true,
                            }}
                        >
                            {/* Page Household information */}
                            <Form.Item
                                name='householdSize'
                                rules={[{ required: true, message: error_message }]}
                                hidden={pageIndex !== 0}
                                label={<strong>How many people, including yourself, live in your household?</strong>}
                            >
                                <Select placeholder='Select one'>
                                    {Array(householdLimit)
                                        .fill(0)
                                        .map((_, i) => (
                                            <Select.Option value={i + 1}>{i + 1}</Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <div hidden={pageIndex !== 0}>
                                <Divider dashed />
                            </div>
                            <Form.Item
                                name='anyDisability'
                                rules={[{ required: true, message: error_message }]}
                                hidden={pageIndex !== 0}
                                label={
                                    <div className='display-inline'>
                                        <Row>
                                            <strong>
                                                Do you, or a member of your household, have a permanent disability or
                                                chronic condition that hinders or limits the amount or kind of
                                                activities you can do?
                                            </strong>
                                        </Row>
                                    </div>
                                }
                                wrapperCol={{ span: 12 }}
                            >
                                <Select placeholder='Select one'>
                                    <Select.Option value={'yes'}>Yes</Select.Option>
                                    <Select.Option value={'no'}>No</Select.Option>
                                    <Select.Option value={'-'}>Prefer not to answer</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={<strong>Is anyone in the household over the age of 50?</strong>}
                                name='anyoneOverSixty'
                                hidden={pageIndex !== 0}
                                rules={[{ required: true, message: error_message }]}
                            >
                                <Radio.Group>
                                    <Radio value={'yes'}>Yes</Radio>
                                    <Radio value={'no'}>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {fullForm && (
                                <>
                                    {/* Page Income 1 */}
                                    <Form.Item
                                        name='householdIncome'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 1}
                                        label={
                                            <div className='display-inline'>
                                                <Row>
                                                    <strong>
                                                        What is your monthly household income before taxes and other
                                                        deductions from jobs or self-employment?
                                                    </strong>
                                                </Row>
                                            </div>
                                        }
                                    >
                                        <InputNumber precision='2' step='0.01' addonBefore='$' />
                                    </Form.Item>
                                    <div hidden={pageIndex !== 1}>
                                        <Divider dashed />
                                    </div>
                                    <Form.Item
                                        name='householdIncomeOtherSources'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 1}
                                        label={
                                            <div className='display-inline'>
                                                <Row>
                                                    <p>
                                                        <strong>
                                                            What is your monthly household income from other sources?
                                                        </strong>{' '}
                                                        Note: These other sources of income includes cash or gifts,
                                                        Supplemental Security Income (SSI), Social Security Retirement
                                                        benefits, veterans benefits, child support, pensions, workers'
                                                        compensation, unemployment compensation, alimony, interest or
                                                        dividends, payment from private insurance.
                                                    </p>
                                                </Row>
                                            </div>
                                        }
                                    >
                                        <InputNumber precision='2' step='0.01' addonBefore='$' />
                                    </Form.Item>
                                    <div hidden={pageIndex !== 1}>
                                        <Divider dashed />
                                    </div>
                                    <Form.Item
                                        name='disabilityPayments'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 1}
                                        label={
                                            <p>
                                                <strong>
                                                    Does someone in your household receive payments due to a disability?
                                                </strong>{' '}
                                                Note: This could include payments through things such as Supplemental
                                                Security Income (SSI), a government pension paid due to a disability, or
                                                through disability payments through Veterans Affairs (the VA).
                                            </p>
                                        }
                                    >
                                        <Radio.Group
                                            value={disPayValue}
                                            onChange={(event) => setDisPayValue(event.target.value)}
                                        >
                                            <Radio value={'yes'}>Yes</Radio>
                                            <Radio value={'no'}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    {/* Page Income 2 */}
                                    <Form.Item
                                        name='rentExpenses'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 2}
                                        label={
                                            <p>
                                                <strong>
                                                    How much did your household pay in rent or mortgage this past month?
                                                </strong>{' '}
                                                Note: Include condominium or association fees.
                                            </p>
                                        }
                                    >
                                        <InputNumber precision='2' step='0.01' addonBefore='$' />
                                    </Form.Item>
                                    <div hidden={pageIndex !== 2}>
                                        <Divider dashed />
                                    </div>
                                    <Form.Item
                                        name='utilitiesExpenses'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 2}
                                        label={
                                            <strong>
                                                How much did your household pay for electricity, gas, basic telephone
                                                service, and other utilities this past month?
                                            </strong>
                                        }
                                    >
                                        <InputNumber precision='2' step='0.01' addonBefore='$' />
                                    </Form.Item>
                                    <div hidden={pageIndex !== 2}>
                                        <Divider dashed />
                                    </div>
                                    <Form.Item
                                        name='anyoneDisabled'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 2}
                                        label={
                                            <strong>
                                                In the past month, did anyone in your household pay for the care of a
                                                child or a disabled person so that a household member could work, attend
                                                training, or look for a job?
                                            </strong>
                                        }
                                    >
                                        <Radio.Group
                                            value={anyDisabled}
                                            onChange={(event) => setAnyoneDisabled(event.target.value)}
                                        >
                                            <Radio value={'yes'}>Yes</Radio>
                                            <Radio value={'no'}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        name='disabilityExpenses'
                                        rules={[{ required: anyDisabled === 'yes', message: error_message }]}
                                        hidden={pageIndex !== 2}
                                        label={
                                            <div className='display-inline'>
                                                <Row>
                                                    <strong>What was the total cost of these care arrangements?</strong>
                                                </Row>
                                            </div>
                                        }
                                    >
                                        <InputNumber
                                            disabled={anyDisabled === 'no'}
                                            precision='2'
                                            step='0.01'
                                            addonBefore='$'
                                        />
                                    </Form.Item>
                                    <div hidden={pageIndex !== 2}>
                                        <Divider dashed />
                                    </div>
                                    <Form.Item
                                        name='seniorExpenses'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 2}
                                        label={
                                            <div className='display-inline'>
                                                <Row>
                                                    <p>
                                                        <strong>
                                                            In the last year, how much did your household pay in
                                                            out-of-pocket medical care for a household member with a
                                                            disability, or household member over the age of 50?
                                                        </strong>{' '}
                                                        Note: Included expenses such as copays for doctor and dentist
                                                        visits, diagnostic tests, prescription medicine, glasses and
                                                        contacts, and medical supplies.
                                                    </p>
                                                </Row>
                                            </div>
                                        }
                                    >
                                        <InputNumber precision='2' step='0.01' addonBefore='$' />
                                    </Form.Item>
                                    <div hidden={pageIndex !== 2}>
                                        <Divider dashed />
                                    </div>
                                    <Form.Item
                                        name='anyoneChildSupport'
                                        rules={[{ required: true, message: error_message }]}
                                        hidden={pageIndex !== 2}
                                        label={
                                            <strong>
                                                In the previous year, did anyone in the household pay child support to
                                                someone outside the household?
                                            </strong>
                                        }
                                    >
                                        <Radio.Group
                                            value={childSupportOutside}
                                            onChange={(event) => setChildSupportOutside(event.target.value)}
                                        >
                                            <Radio value={'yes'}>Yes</Radio>
                                            <Radio value={'no'}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <div hidden={pageIndex !== 2}>
                                        <Divider dashed />
                                    </div>
                                    <Form.Item
                                        name='childSupportExpenses'
                                        rules={[{ required: childSupportOutside === 'yes', message: error_message }]}
                                        hidden={pageIndex !== 2}
                                        label={
                                            <div className='display-inline'>
                                                <Row>
                                                    <strong>What was the total amount paid in child support?</strong>
                                                </Row>
                                            </div>
                                        }
                                    >
                                        <InputNumber
                                            disabled={childSupportOutside === 'no'}
                                            precision='2'
                                            step='0.01'
                                            addonBefore='$'
                                        />
                                    </Form.Item>
                                </>
                            )}

                            {/* Page Demographic Information */}
                            <Form.Item
                                name='yearBorn'
                                rules={[{ required: true, message: error_message }]}
                                hidden={pageIndex !== 3}
                                label={<strong>What year were you born?</strong>}
                            >
                                <DatePicker onChange={onYearChange} picker='year' />
                            </Form.Item>
                            <div hidden={pageIndex !== 3}>
                                <Divider dashed />
                            </div>
                            <Form.Item
                                name='ethnicity'
                                hidden={pageIndex !== 3}
                                label={
                                    <div className='display-inline'>
                                        <Row>
                                            <strong>What is your ethnicity?</strong>
                                        </Row>
                                    </div>
                                }
                                wrapperCol={{ span: 12 }}
                            >
                                <Select placeholder='Select one'>
                                    {ethnicities.map((value) => (
                                        <Select.Option value={value}>{value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <div hidden={pageIndex !== 3}>
                                <Divider dashed />
                            </div>
                            <Form.Item
                                name='races'
                                hidden={pageIndex !== 3}
                                label={
                                    <div className='display-inline'>
                                        <Row>
                                            <strong>
                                                Which of the following best describes your race? (select all that apply)
                                            </strong>
                                        </Row>
                                    </div>
                                }
                                wrapperCol={{ span: 12 }}
                            >
                                <Select
                                    mode={'multiple'}
                                    maxTagCount={'responsive'}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                Prefer to self-describe
                                                <Input
                                                    value={raceInSpecify}
                                                    placeholder='Please specify'
                                                    onChange={onChangeRaceSpecify}
                                                    ref={raceInSpecifyInputRef}
                                                />
                                                <Button
                                                    type='text'
                                                    icon={<PlusCircleFilled />}
                                                    onClick={addRaceItem}
                                                ></Button>
                                            </Space>
                                        </>
                                    )}
                                    options={racesState}
                                />
                            </Form.Item>
                            <div hidden={pageIndex !== 3}>
                                <Divider dashed />
                            </div>
                            <Form.Item
                                name='genderIdentity'
                                hidden={pageIndex !== 3}
                                label={
                                    <div className='display-inline'>
                                        <Row>
                                            <strong>What is your current gender identity?</strong>
                                        </Row>
                                    </div>
                                }
                                wrapperCol={{ span: 12 }}
                            >
                                <Select
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                Prefer to self-describe
                                                <Input
                                                    value={genderInSpecify}
                                                    placeholder='Please specify'
                                                    onChange={onChangeGenderSpecify}
                                                    ref={genderInSpecifyInputRef}
                                                />
                                                <Button
                                                    type='text'
                                                    icon={<PlusCircleFilled />}
                                                    onClick={addGenderItem}
                                                ></Button>
                                            </Space>
                                        </>
                                    )}
                                    options={gendersState}
                                />
                            </Form.Item>

                            <Form.Item
                                name='zipCode'
                                rules={[
                                    {
                                        pattern: '^\\d{5}(?:[-\\s]\\d{4})?$',
                                        message: 'zip code must be 5 digits xxxxx, or the extended ZIP+4 xxxxx-xxxx',
                                    },
                                ]}
                                hidden={pageIndex !== 3}
                                label={<strong>ZIP Code</strong>}
                            >
                                <Input placeholder='12345' />
                            </Form.Item>
                            <div hidden={pageIndex !== 3}>
                                <Divider dashed />
                            </div>

                            <Form.Item hidden={pageIndex !== lastPageIndex}>
                                <Button type='primary' htmlType='submit'>
                                    Submit
                                </Button>
                            </Form.Item>

                            <div hidden={pageIndex === lastPageIndex}>
                                <Button type='primary' onClick={handleContinue}>
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    </Space>
                </Col>
            </Row>
        </Spin>
    );
}

export default SNAPForm;
