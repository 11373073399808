import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import CalculatorDescription from '../../components/CalculatorDescription';

const { Title } = Typography;

function Landing() {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/contact-info');
    };

    return (
        <Col>
            <Row className='row-padding' justify='space-around' align='middle'>
                <Col span={20}>
                    <Title>Welcome to the SNAP Benefit Indicator!</Title>
                    <CalculatorDescription />
                </Col>
            </Row>
            <Row className='row-padding' justify='space-around' align='middle'>
                <Col span={6}>
                    <Row>
                        <Button type='primary' style={{ width: '100%' }} onClick={handleGetStarted}>
                            Get Started
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
}

export default Landing;
