import { Row } from 'antd';
import React from 'react';

function FooterContents({ disclaimer }) {
    return (
        disclaimer && (
            <>
                <Row>
                    <p>
                        <strong>Disclaimer:</strong> {disclaimer}
                    </p>
                </Row>
            </>
        )
    );
}

export default FooterContents;
